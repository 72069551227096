import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import ResponsiveMedia from "Primitive/ResponsiveMedia"
import Type from  "Primitive/Type"
import Container from  "Primitive/Container"

import vid from "../../../asset/solution.mp4"
import poster from '../../../images/hero_poster.jpg'

import styles from "./Hero.module.scss"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1366) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div id="home" className={styles.Hero}>
      <ResponsiveMedia ratio={9 / 16} className={styles.ResponsiveMedia}>
        <video className={styles.VideoFrame} poster={poster} autoPlay muted loop>
          <source src={vid} type="video/mp4" />
        </video>
        <Img
          className={styles.HeroImage}
          fluid={data.hero.childImageSharp.fluid}
        />
      </ResponsiveMedia>
      <Container gutter center className={styles.Content}>
        <Type size="displayHero" as="h2" className={styles.Title}>
          Making Entertainment Easy
        </Type>
        <Type size="subtitle" as="p" className={styles.Subtitle}>
          Colooder is a digital fan experience platform linking the physical
          experience with digital services
        </Type>
      </Container>
    </div>
  )
}

Hero.propTypes = {}

export default Hero
