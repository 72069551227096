import React, { useState, useEffect, useRef } from "react"
import { func, bool } from "prop-types"
import classNames from "classnames"

import SmartLink from "Primitive/SmartLink"
import Icon from "Primitive/Icon"
import Container from  "Primitive/Container"

import styles from "./Header.module.scss"

const Header = ({ onShowNav, onHideNav, showNav }) => {
  const [dropdown, setDropdown] = useState(false)
  const [navBackground, setNavBackground] = useState(false)
  const navRef = useRef()
  navRef.current = navBackground
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 60
      if (navRef.current !== show) {
        setNavBackground(show)
      }
    }
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  function scrollToPage(sectionId) {
    onHideNav()
    document &&
      document.querySelector(sectionId).scrollIntoView({
        behavior: "smooth",
      })
  }

  return (
    <Container
      gutter
      className={classNames(styles.Header, navBackground && styles.opaque)}
    >
      <Icon
        className={styles.Logo}
        type={"logo-white-landscape"}
        a11yText="Colooder"
        width={160}
        height={26}
        onClick={() => scrollToPage("#home")}
      />
      <nav className={classNames(styles.HeaderNav, showNav && styles.isActive)}>
        <SmartLink
          className={styles.DropDownButton}
          onClick={() => setDropdown(!dropdown)}
        >
          Platform
        </SmartLink>
        <div
          className={classNames(
            styles.DropDown,
            dropdown && styles.dropdownOpen
          )}
        >
          <SmartLink
            onClick={() => scrollToPage("#fans")}
            className={styles.DropDownLink}
          >
            Fans
          </SmartLink>
          <SmartLink
            onClick={() => scrollToPage("#rights-holders")}
            className={styles.DropDownLink}
          >
            Rights Holders
          </SmartLink>
          <SmartLink
            onClick={() => scrollToPage("#colooder-xms")}
            className={styles.DropDownLink}
          >
            Colooder XMS
          </SmartLink>
        </div>
        <SmartLink
          className={styles.NavLink}
          onClick={() => scrollToPage("#about-us")}
        >
          About us
        </SmartLink>
        <SmartLink
          className={styles.NavLink}
          onClick={() => scrollToPage("#partners")}
        >
          Partners
        </SmartLink>
        <SmartLink
          className={classNames(styles.NavLink, styles.Button)}
          onClick={() => scrollToPage("#contact-us")}
        >
          Contact us
        </SmartLink>
      </nav>
      <div className={styles.SocialIcons}>
        <SmartLink
          className={styles.SocialIcon}
          href="https://twitter.com/colooder"
        >
          <Icon
            className={styles.IconTw}
            type="twitter"
            height={30}
            width={20}
            a11yText="Twitter"
          />
        </SmartLink>
        <SmartLink
          className={styles.SocialIcon}
          href="https://www.facebook.com/Colooder/"
        >
          <Icon
            className={styles.IconFb}
            type="facebook"
            height={30}
            width={10}
            a11yText="Facebook"
          />
        </SmartLink>
        <SmartLink
          className={styles.SocialIcon}
          href="https://www.linkedin.com/company/colooder/"
        >
          <Icon
            className={styles.IconLi}
            type="linkedin"
            height={30}
            width={20}
            a11yText="LinkedIn"
          />
        </SmartLink>
      </div>
      <Icon
        onClick={showNav ? onHideNav : onShowNav}
        className={styles.HeaderToggle}
        type="menu"
        width={30}
        height={30}
        a11yText="Menu toggle"
      />
    </Container>
  )
}

Header.propTypes = {
  onShowNav: func,
  onHideNav: func,
  showNav: bool,
}

export default Header
