import React from "react"

import Container from "Primitive/Container"
import SmartLink from "Primitive/SmartLink"
import Icon from "Primitive/Icon"

import styles from "./Footer.module.scss"

const Footer = () => (
  <Container gutter as="footer" className={styles.Footer}>
    Copyright © {new Date().getFullYear()}, Colooder. All rights reserved.
    <div className={styles.SocialIcons}>
      <SmartLink
        className={styles.SocialIcon}
        href="https://twitter.com/colooder"
      >
        <Icon
          className={styles.IconTw}
          type="twitter"
          height={30}
          width={20}
          a11yText="Twitter"
        />
      </SmartLink>
      <SmartLink
        className={styles.SocialIcon}
        href="https://www.facebook.com/Colooder/"
      >
        <Icon
          className={styles.IconFb}
          type="facebook"
          height={30}
          width={10}
          a11yText="Facebook"
        />
      </SmartLink>
      <SmartLink
        className={styles.SocialIcon}
        href="https://www.linkedin.com/company/colooder/"
      >
        <Icon
          className={styles.IconLi}
          type="linkedin"
          height={30}
          width={20}
          a11yText="LinkedIn"
        />
      </SmartLink>
    </div>
  </Container>
)

export default Footer
