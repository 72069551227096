import React, { useState } from "react"
import PropTypes from "prop-types"
import { ParallaxProvider } from "react-scroll-parallax"

import Header from "./Common/Header"
import Hero from "./Common/Hero"
import Footer from "./Common/Footer"

const Layout = ({ children }) => {
  const [showNav, setToggleNav] = useState(false)

  function handleShowNav() {
    setToggleNav(true)
  }
  function handleHideNav() {
    setToggleNav(false)
  }

  return (
    <div>
      <Header
        onShowNav={handleShowNav}
        onHideNav={handleHideNav}
        showNav={showNav}
      />
      <Hero />
      <div>
        <ParallaxProvider>
          <main>{children}</main>
        </ParallaxProvider>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
